// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../fonts/OpenSans-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../fonts/OpenSans-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../fonts/OpenSans-Italic.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../fonts/OpenSans-ExtraBold.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../fonts/OpenSans-BoldItalic.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, ".text-0 {\n  color: #555;\n}\n.text-1 {\n  color: #888;\n}\n.text-2 {\n  color: #aaa;\n}\n.text-3 {\n  color: #d2d2d2;\n}\n.special-bg {\n  background-color: #a300dd;\n}\n.special-border {\n  border-color: #a300dd;\n}\n.club-bg {\n  background-color: #ff0000;\n}\n.club-border {\n  border-color: #ff0000;\n}\n@font-face {\n  font-family: 'Open Sans';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype');\n}\n@font-face {\n  font-family: 'Open Sans';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('truetype');\n  font-weight: bold;\n}\n@font-face {\n  font-family: 'Open Sans';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('truetype');\n  font-style: italic;\n}\n@font-face {\n  font-family: 'Open Sans';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('truetype');\n  font-weight: 900;\n}\n@font-face {\n  font-family: 'Open Sans';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format('truetype');\n  font-weight: bold;\n  font-style: italic;\n}\n.box-content {\n  max-width: 700px;\n  margin-left: auto;\n  margin-right: auto;\n  margin-bottom: 25px;\n}\n.box-content :is(.btn, .dropdown-item) [class^=\"bi-\"]::before {\n  padding-right: 0.5em;\n  vertical-align: middle;\n}\n.box {\n  border: 4px solid #3d3b45;\n  border-radius: 6px;\n  padding: 25px;\n  background-color: white;\n}\n.box h2 {\n  margin: 0;\n}\n", ""]);
// Exports
module.exports = exports;
