import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import contexts from '../../context/contexts';
import './PhotoGallery.less';

const {
	useStore,
	useDispatch,
	makeDispatcher
} = contexts.gallery;

const GalleryContent = ({ id, name, image, count }) => {
	return (
		<div className="box-content box gallery-content">
			<div className="details">
				<h2>
					<Link to={`/photos/${id}`}>{name}</Link>
				</h2>
				<p>
					{count} photos
				</p>
			</div>
			<Link to={`/photos/${id}`}>
				<img src={image} alt={name} />
			</Link>
		</div>
	);
}

function renderGalleries(galleries) {
	return galleries.map(gallery => <GalleryContent key={gallery.id} {...gallery} />);
}

const PhotoGallery = (params) => {
	const dispatch = makeDispatcher(useDispatch());
	const { gallery } = useStore();

	useEffect(() => {
		if (!gallery.data.length) {
			dispatch({ type: 'FETCH_GALLERY' });
		}
	}, []); // the [] is for when mounting only

	if (!gallery.finishedLoading) {
		return null;
	}

	if (!gallery.data.length) {
		return null;
	}

	return (
		<div className="gallery">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					An Góilín Traditional Singers - Photos
				</title>
			</Helmet>
			<h1>Gallery</h1>
			{renderGalleries(gallery.data)}
		</div>
	);
};

export default PhotoGallery;
