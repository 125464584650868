import React, { useEffect, useState, useCallback } from 'react';
import ImageGallery from 'react-image-gallery';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { LinkContainer } from 'react-router-bootstrap';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import contexts from '../../context/contexts';
import './Photos.less';

const {
	useStore,
	useDispatch,
	makeDispatcher
} = contexts.photos;

const Photos = ({ match }) => {
	const dispatch = makeDispatcher(useDispatch());
	const { photos } = useStore();
	const { id } = useParams()

	useEffect(() => {
		if (!photos.data[id]) {
			dispatch({ type: 'FETCH_PHOTOS', payload: id });
		}
	}, []); // the [] is for when mounting only

	if (!photos.finishedLoading) {
		return null;
	}

	if (!photos.data[id]) {
		return null;
	}

	const images = photos.data[id].map(x => ({
		original: x.src,
		originalWidth: x.width,
		originalHeight: x.height,
		thumbnail: x.src,
		thumbnailHeight: 100
	}));

	return (
		<div className="photos">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					An Góilín Traditional Singers - Photos {id}
				</title>
			</Helmet>
			<h1>
				<Breadcrumb>
					<LinkContainer to="/photos">
						<Breadcrumb.Item>Photos</Breadcrumb.Item>
					</LinkContainer>
					<Breadcrumb.Item active>
						{id}
					</Breadcrumb.Item>
				</Breadcrumb>
			</h1>
			<div className="box-content box">
				<ImageGallery
					showBullets
					autoPlay
					items={images}
				/>
			</div>
		</div>
	);
};

export default Photos;
