
const MONTHS = [
	'',
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
];
const MONTHS_ABBREV = [
	'',
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec'
];

export function getNextFridayDate() {
	const date = new Date();
	while (date.getDay() != 5) {
		date.setDate(date.getDate() + 1);
	}
	return date.toISOString().split('T')[0];
}

export function compareDates(a, b) {
	return a.date > b.date ? 1 : a.date < b.date ? -1 : 0;
}

export function addEventMetadata(event) {
	const date = event.date.split('-');
	const month = parseInt(date[1]);
	event.dateYear = date[0];
	event.dateMonth = MONTHS[month];
	event.dateMonthAbbrev = MONTHS_ABBREV[month];
	event.dateDay = date[2];
	return event;
}

export function removeEventMetadata(event) {
	delete event.dateYear;
	delete event.dateMonth;
	delete event.dateMonthAbbrev;
	delete event.dateDay;
	return event;
}

