import React from 'react';

class DonateButton extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<form
				action="https://www.paypal.com/cgi-bin/webscr"
				id="donate"
				method="post"
				target="_top"
				className="donate nav-link"
			>
				<input type="hidden" name="cmd" value="_s-xclick" />
				<input type="hidden" name="hosted_button_id" value="TV5N8NL5HMV4N" />
				<input
					className="btn btn-warning"
					type="submit"
					name="submit"
					title="PayPal - The safer, easier way to pay online!"
					value="Donate"
				/>
			</form>
		);
	}
}

export default DonateButton;
