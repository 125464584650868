import React from 'react';
import './Loading.less';

const Loading = ({ title }) => {
	// https://loading.io/css/
	return (
		<div className="box-content box loading">
			<div className="lds-ring"><div></div><div></div><div></div><div></div></div>
			{title}
		</div>
	);
}

export default Loading;
