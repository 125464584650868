import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import contexts from '../../context/contexts';
import Item from '../../widgets/Item';
import './Shop.less';

const {
	useStore,
	useDispatch,
	makeDispatcher
} = contexts.shop;

const Shop = () => {
	const dispatch = makeDispatcher(useDispatch());
	const { shop } = useStore();

	useEffect(() => {
		if (!shop.data.length) {
			dispatch({ type: 'FETCH_SHOP' });
		}
	}, []); // the [] is for when mounting only

	if (!shop.data.length) {
		return null;
	}

	const items = shop.data.map(item =>
		<Item
			key={item.id}
			title={item.name}
			description={item.description}
			image={item.image}
			by={item.by}
			price={item.price}
			tags={item.contents}
		/>
	);

	return (
		<div className="shop">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					An Góilín Traditional Singers - Shop
				</title>
				<meta name="description" content="Items for sale from an Góilín Traditional Singers" />
				<meta name="keywords" content="singing,traditional singers,irish song,albumbs,music,cd,dvd,t-shirts" />
			</Helmet>
			<h1>Shop</h1>
			{items}
		</div>
	);
};

export default Shop;
