import React from 'react';
import Badge from 'react-bootstrap/Badge';
import ReactMarkdown from 'react-markdown';
import './Item.less';

const Item = ({
	title,
	description,
	image,
	url = null,
	icon = null,
	by = null,
	price = null,
	tags = []
}) => {
	const badges = [];
	for (const tag of tags) {
		let variant = 'secondary';
		if (tag.includes('CD') || tag.includes('pdf')) {
			variant = 'danger';
		} else if (tag.includes('tracks')) {
			variant = 'secondary';
		} else if (tag.includes('booklet') || tag.includes('fhf')) {
			variant = 'primary';
		}
		badges.push(
			<Badge key={tag} variant={variant}>{tag}</Badge>
		);
	}

	let header;
	if (url) {
		header = (
			<h2>
				<a
					href={url}
					title={title}
					target="_blank"
				>
					{icon && <span className={`icon ${icon}`}></span>}
					{title}
				</a>
			</h2>
		);
	} else {
		header = (
			<h2>
				{icon && <span className={`icon ${icon}`}></span>}
				{title}
			</h2>
		);
	}

	return (
		<div className="box-content box item">
			<div className="item-banner">
				{price && <div className="price">€{price}</div>}
				{header}
			</div>
			{by && <ReactMarkdown className="text-1">{by}</ReactMarkdown>}
			<div className="item-details">
				{image && <img src={image} alt={title} />}
				<div className="description">
					<ReactMarkdown>
						{description}
					</ReactMarkdown>
				</div>
			</div>
			<div className="tags">
				{badges}
			</div>
		</div>
	);
}

export default Item;
