import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import gclef from '../../../images/angoilin.svg';
import './Songs.less';

const Songs = () => {
	return (
		<div className="songs">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					An Góilín Traditional Singers - Irish song database
				</title>
			</Helmet>
			<h1>An Góilín Song Project</h1>
			<div className="box-content box">
				<img src={gclef} className="gclef" />
				The ITMA host the <a target="_blank" href="https://www.itma.ie/goilin">An Góilín Song Project</a>,
				a collection of songs in English
				and Irish of the Góilín Traditional Singers.  There are over 700 audio
				recordings, with photographs, printed items, singer profiles and interviews.
				<span style={{ clear: 'left', display: 'block' }}></span>
			</div>
		</div>
	);
};

export default Songs;
